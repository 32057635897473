<template>
  <v-container class="mt-5">

    <v-skeleton-loader
      type="table-heading, list-item-two-line"
      v-if="$apollo.loading"
    ></v-skeleton-loader>
     <v-card color="transparent" flat class="" v-else>
          <v-card-title>
            <h3 class="font-weight-bold"> {{ challenge.title }}</h3>
          </v-card-title>
          <v-card-text>
           <span class="mt-3">{{ challenge.preTitle}}</span>
          </v-card-text>
    </v-card>
    
    
    <v-skeleton-loader
      type="article, actions"
      v-if="$apollo.loading"
      class="mt-8"
    ></v-skeleton-loader>
    <v-card min-height="100" class="mt-5" flat v-else>
      <v-card-text v-html="challenge.description">
        <vue-mathjax :formula="mardDownHtml(challenge.description)" :safe="false"></vue-mathjax>
      </v-card-text>
    </v-card> 

    <v-btn :color="primaryBlue" dark class="pa-5 mt-5 ml-5" :class="{'black--text': theme }" :to="`/challenge/${$route.params.id}/${problemPath}`" :disabled="$apollo.loading">
      ATTEMPT
    </v-btn>

  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { CHALLENGE_OVERVIEW } from '@/queries/challenge';
import marked from 'marked';
import { VueMathjax } from 'vue-mathjax';

export default {
  name: 'AboutChallenge',
  mixins: [colorMxn],
  components: {
    VueMathjax,
  },
  data() {
    return {
      latex: '',
      challenge: null,
    }
  },
  apollo: {
    challenge: {
      query: CHALLENGE_OVERVIEW,
      variables(){
        return {
          'challengePk': parseInt(this.$route.params.id),
          "problemSetFirst": 1,
          "chapterSetFirst": 1,
        }
      },
      update: (data) => {
        console.log(data);
        return data.challenge;
      }
    }
  },
  computed: {
    problemPath() {
      const result = this.challenge ? this.challenge.chapterSet.edges[0].node.problemSet.edges[0].node : ''
      return result ? `${this.challenge.chapterSet.edges[0].node.pk}/${result.title}/${result.pk}` : null;
    },
  },
  methods: {
    mardDownHtml(description) {
      return description ? marked(description) : '';
    }
  }

}
</script>
